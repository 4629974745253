
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  font-family: Noto Sans SC;
}

html {
  font-size: 62.5%;
}

body {
  background-color: rgb(240, 240, 240);
  background-attachment: fixed;
  background-size: cover;
}

.main-body {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
}


::-webkit-scrollbar {
  width: 0px;
}

@media screen and (max-width:1000px){
  .site-list-content {
    width: 75rem;
  }
}

@media screen and (max-width:750px){
  .site-list-content {
    width: 50rem;
  }
}

@media screen and (max-width:500px){
  .site-list-content {
    width: 90%;
  }

  .open-button {
    margin-left: 5%;
  }

  .site {
    width: 20%;
    height: 0;
    padding-bottom: 20%;
    position: relative;
    display: block;
    margin: 2.5%;
    overflow: visible;
  }

  .site-icon {
    position: absolute;
    width: 70%;
    height: 70%;
    left: 15%;
    top: 15%;
    border-radius: 28%;
  }

  .site-icon img {
    width: 70%;
    height: 70%;
    border-radius: 22%;
  }

  .site-name {
    width: 80%;
    position: absolute;
    bottom: -15%;
    left: 50%;
    transform: translateX(-50%);
  }

  .search {
    width: 75%;
  }

  .scrolled {
    margin-bottom: 3rem;
  }
}