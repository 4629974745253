.site {
  width: 10rem;
  height: 10rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 1rem;
  margin: 1rem 1.25rem;
}

.site-icon {
  width: 7rem;
  height: 7rem;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 2rem;
  cursor: pointer;
}

.site-icon img {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 1rem;
}

.site-name {
  color: #000;
  height: 1.8rem;
  width: 8rem;
  white-space:nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
}