.site-list-content {
  margin-bottom: 3rem;
  width: 100rem;
  padding: 1rem 0;
  border-radius: 1rem;
  overflow: hidden;
  transition: height 0.5s;
  background-color: rgba(255, 255, 255, 0.7);
}

.list-header {
  width: 100%;
  cursor: pointer;
}

.site-list {
  list-style: none;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.list-header {
  font-size: 2rem;
  color: #000;
  display: flex;
  align-items: center;
}

.open-button {
  background-color: transparent;
  color: #000;
  margin-right: 0.5rem;
  margin-left: 2rem;
  cursor: pointer;
}

.list-header span {
  display: block;
  height: 3.2rem;
}

.open-button-icon {
  font-size: 1.7rem;
}

.hidden {
  display: none;
}








