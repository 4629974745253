.search {
  width:50%;
  height: 5rem;
  background-color: rgb(240, 240, 240);
  display: flex;
  flex-direction: row;
  border-radius: 999px;
  overflow: hidden;
  padding-left: 2.2rem;
  align-items: center;
  transition:margin 0.5s;
}

.scrolled {
  margin-bottom: 10rem;
}

.search-input-content {
  flex-shrink: 1;
  width: 100%;
  height: 2.8rem;
  background-color: transparent;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
}

.search-input {
  width: 100%;
  height: 2.2rem;
  font-size: 1.6rem;
  background-color: transparent;
  color: rgb(83, 83, 83);
}

.underline {
  position: absolute;
  display: block;
  width: 0%;
  height: 0.1rem;
  background-color: rgb(199, 199, 199);
  transition: width 0.5s;
  bottom: 0;
}

.search-input:focus + .underline {
  width: 100%;
}

.search-input::placeholder {
  color: rgb(206, 206, 206);
}

.search-button {
  margin: 0 0.5rem;
  margin-left: 0;
  width: 4rem;
  height: 4rem;
  flex-shrink: 0;
  background-color: transparent;
  cursor: pointer;
  border-radius: 999px;
  transition: transform 0.1s;
}


.search-button:hover{
  transform: scale(1.2);
}

.search-button:active {
  transform: scale(1);

}


